@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
/* overflow-y: hidden; */
}
